import freefield01 from './data/freefield01.json';
import freefield02 from './data/freefield02.json';
import freefield09 from './data/freefield09.json';
import trademark from './data/trademark.json';


export default {
  props: ['order'],
  data () {
    return {
      isLoading: false,
      freeField01: freefield01,
      freeField02: freefield02,
      trademark: trademark,
      types: [],
      domains: [],
      freeFieldOneSelected: [],
      freeFieldTwoSelected: [],
      checkboxChildren: '',
      checkboxGender: '',
      checkboxLivretSport: '',
      postal_codes: [],
      loyalty_points: [0, 0],
      isAdvancedOpen: false,
      isClassicOpen: true,
      ages: [18, 100],
      dates: [],
      visitCount: [0, 0],
      gross_sales: [0, 0],
      selectedTypes: [],
      selectedDomains: [],
      selectedTrademark: [],
      apiUrlParameters: "",
      targetingTarget: 0,
      smsUnityPrice: 0.0395,
      department: [],
      departments: freefield09,
    }
  },
  async mounted() {
    this.isLoading = true;

    this.prepareTargeting(this.order.targeting_filters, true);
    if (this.order.disabledAction) {
      this.order.targetNumber = this.order.targeting_filters.nb_contacts || 0;
      this.order.priceEstimation = this.order.targeting_filters.price_contacts || 0;
    } else {
      // Luanch price estimation.
      this.priceEstimation();
    }

    await this.$cx.get(`/fedas/get-fedas-activity-levels/`)
      .then(({level_1, level_2}) => {
        this.types = [...level_1]
          level_2.forEach(element => {
            let cat = level_1.find(e => e.value == parseInt(String(element["value"]).charAt(0))) || []
            this.domains = [...this.domains, {
              "value": element.value,
              "title": `${element.title} (${cat.title})`
            }]
          });
        // Select all elements that was selected before.
        this.selectedTypes.forEach(s => this.$refs[s][0].selected = true)
        this.selectedDomains.forEach(s => this.$refs[s][0].selected = true)
        this.selectedTrademark.forEach(s => this.$refs[s][0].selected = true)
      });

    this.isLoading = false
  },
  watch: {
    targetingTarget: function(event) {
      if (this.order.saveTargeting) {
        let targetSelect = this.order.saveTargeting.find(target => target.id === event)
        this.order.priceEstimation = targetSelect.priceEstimation;
        this.order.targetNumber = targetSelect.targetNumber;
        this.prepareTargeting(JSON.parse(Object.freeze(JSON.stringify(targetSelect.targeting_filters))));
      }
    }
  },
  methods: {
    getFilteredDepartement(text) {
      text = text.toLowerCase()

      this.departments = freefield09.filter((option) => option.label
          .toString()
          .toLowerCase()
          .indexOf(text) >= 0
      )
    },
    prepareTargeting(targetingFilters, init=false) {

      this.reset(true);

      // Init props.
      if (targetingFilters["bill_begin_date"] && targetingFilters["bill_end_date"]){
        let bill_begin_date = new Date(
          this.$moment(
            this.$moment(
              targetingFilters["bill_begin_date"], 'YYYY-MM-DD HH:mm:ss'
            ).format('YYYY-MM-DD')
          ).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        );

        let bill_end_date = new Date(
          this.$moment(
            this.$moment(
              targetingFilters["bill_end_date"], 'YYYY-MM-DD HH:mm:ss'
            ).format('YYYY-MM-DD')
          ).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        );

        this.dates = [bill_begin_date, bill_end_date] || []
      } else {
        this.dates = []
      }

      if (targetingFilters["max_tickets"]){
        let min_tickets = targetingFilters["min_tickets"]
        let max_tickets = targetingFilters["max_tickets"]
        this.visitCount = [min_tickets, max_tickets]
      } else {
        this.visitCount = [0 , 0]
      }

      if (targetingFilters["max_ttc"]){
        let min_ttc = targetingFilters["min_ttc"]
        let max_ttc = targetingFilters["max_ttc"]
        this.gross_sales = [min_ttc, max_ttc]
      } else {
        this.gross_sales = [0, 0]
      }

      this.freeFieldOneSelected = targetingFilters["free_field_01"] || []
      this.freeFieldTwoSelected = targetingFilters["free_field_02"] || []
      this.checkboxChildren = targetingFilters["free_field_03"] || ''
      this.checkboxGender = targetingFilters["free_field_08"] || ''
      this.checkboxLivretSport = targetingFilters["free_field_04"] || ''
      this.postal_codes = targetingFilters["free_field_05"] || []
      let free_field_09 = targetingFilters["free_field_09"] || ""
      this.department = this.departments.filter(dep => free_field_09.includes(dep.id)) || []
      this.loyalty_points = targetingFilters["free_field_07"] || [0, 0]
      this.ages = targetingFilters["free_field_06"] || [18, 100]
      this.selectedTypes = targetingFilters["productcat01"] || []
      this.selectedDomains = targetingFilters["productcat02"] || []
      this.selectedTrademark = targetingFilters["productbrand"] || []

      // Select all elements that was selected before.
      this.freeFieldOneSelected.forEach(s => this.$refs[s][0].classList.add("is-active"))
      this.freeFieldTwoSelected.forEach(s => this.$refs[s][0].classList.add("is-active"))

      if (!init) {
        this.order.targeting_filters = targetingFilters;
      }

      if(!this.order.disabledAction) {
        this.order.targeting_filters.nb_contacts = this.order.targetNumber;
        this.order.targeting_filters.price_contacts = this.order.priceEstimation;
      }
    },
    removeTargeting(targetingId) {
      let removeIndex = this.order.saveTargeting.map(function(target) { return target.id; }).indexOf(targetingId);
      let saveIndex = this.order.saveTargeting.findIndex(el => el.id === this.targetingTarget);
      this.order.saveTargeting.splice(removeIndex, 1);
      if(saveIndex === removeIndex) {
        this.targetingTarget = this.order.saveTargeting[this.order.saveTargeting.length - 1].id;
      }
    },
    uniqInt() {
      return Math.floor(Math.random()*Math.floor(Date.now())) + Math.floor(Date.now());
    },
    priceEstimation() {
      // Activate loading mode.
      this.$store.state.action.disableEstimateButton = true

      this.$me.get(`/campaigns-orders/get-targeting/?campaign_order=${this.$route.params.orderId}${this.craftUrl()}`)
        .then(({count}) => {
          let estimation = this.order.channel === 2 ? this.smsUnityPrice * count : this.order.channel === 1 ? 0 : 0;

          this.order.targetNumber = count;
          this.order.priceEstimation = estimation;

          let uniqInt = this.uniqInt();

          this.order.saveTargeting.push({
            id: uniqInt,
            targetNumber: count,
            priceEstimation: estimation,
            targeting_filters: JSON.parse(Object.freeze(JSON.stringify(this.order.targeting_filters)))
          });

          this.targetingTarget = uniqInt;
        })
        .finally(() => {
          // Disable loading mode.
          this.$store.state.action.disableEstimateButton = false
        });
    },
    craftUrl() {
      let apiUrlParameters = '';
      this.order.targeting_filters = {}

      if (this.freeFieldOneSelected.length > 0) {
        apiUrlParameters += '&' + this.freeFieldOneSelected.map(f => `free_field_01=${f}`).join('&')
        this.order.targeting_filters["free_field_01"] = this.freeFieldOneSelected
      }
      if (this.freeFieldTwoSelected.length > 0) {
        apiUrlParameters += '&' + this.freeFieldTwoSelected.map(f => `free_field_02=${f}`).join('&')
        this.order.targeting_filters["free_field_02"] = this.freeFieldTwoSelected
      }
      if (this.order.channel === 1) {
        apiUrlParameters += '&optin_email=1'
        this.order.targeting_filters['optin_email'] = '1'
      }
      if (this.order.channel == 2) {
        apiUrlParameters += '&optin_sms=1'
        this.order.targeting_filters['optin_sms'] = '1'
      }
      if (this.order.channel == 3) {
        apiUrlParameters += '&optin_letter=1'
        this.order.targeting_filters['optin_letter'] = '1'
      }
      if (this.checkboxChildren) {
        apiUrlParameters += `&free_field_03=${this.checkboxChildren}`
        this.order.targeting_filters["free_field_03"] = this.checkboxChildren
      }
      if (this.checkboxGender) {
        apiUrlParameters += `&free_field_08=${this.checkboxGender}`
        this.order.targeting_filters["free_field_08"] = this.checkboxGender
      }
      if (this.checkboxLivretSport) {
        apiUrlParameters += `&free_field_04=${this.checkboxLivretSport}`
        this.order.targeting_filters["free_field_04"] = this.checkboxLivretSport
      }
      if (this.postal_codes.length > 0) {
        apiUrlParameters += `&free_field_05=[${this.postal_codes}]`
        this.order.targeting_filters["free_field_05"] = this.postal_codes
      }
      if (this.department.length > 0) {
        let deps = Array.from(this.department, obj => obj.id)
        // // deps = deps.replace("'", '').replace(" ", '')
        // apiUrlParameters += `&free_field_09=[${deps}]`
        // this.order.targeting_filters["free_field_09"] = deps
        apiUrlParameters += '&' + deps.map(f => `free_field_09=${f}`).join('&')
        this.order.targeting_filters["free_field_09"] = deps
      }
      if (this.dates.length) {
        let bill_begin_date = this.$moment(this.$moment(this.$moment(this.dates[0]).format("YYYY-MM-DD HH:mm:ss")).format('YYYY-MM-DD')).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        let bill_end_date = this.$moment(this.$moment(this.$moment(this.dates[1]).format("YYYY-MM-DD HH:mm:ss")).format('YYYY-MM-DD')).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        apiUrlParameters += `&bill_begin_date=${bill_begin_date}&bill_end_date=${bill_end_date}`
        this.order.targeting_filters["bill_begin_date"] = bill_begin_date
        this.order.targeting_filters["bill_end_date"] = bill_end_date
      }
      if (this.visitCount[1] >= 1) {
        apiUrlParameters += `&min_tickets=${this.visitCount[0]}&max_tickets=${this.visitCount[1]}`
        this.order.targeting_filters["min_tickets"] = this.visitCount[0]
        this.order.targeting_filters["max_tickets"] = this.visitCount[1]
      }
      if (this.gross_sales[1] >= 1) {
        apiUrlParameters += `&min_ttc=${this.gross_sales[0]}&max_ttc=${this.gross_sales[1]}`
        this.order.targeting_filters["min_ttc"] = this.gross_sales[0]
        this.order.targeting_filters["max_ttc"] = this.gross_sales[1]
      }
      if (this.selectedTypes.length) {
        apiUrlParameters += `&productcat01=[${this.selectedTypes}]`
        this.order.targeting_filters["productcat01"] = this.selectedTypes
      }
      if (this.selectedDomains.length) {
        apiUrlParameters += `&productcat02=[${this.selectedDomains}]`
        this.order.targeting_filters["productcat02"] = this.selectedDomains
      }
      if (this.selectedTrademark.length) {
        apiUrlParameters += `&productbrand=[${this.selectedTrademark}]`
        this.order.targeting_filters["productbrand"] = this.selectedTrademark
      }
      if (this.ages[1] >= 1) {
        this.ages[1] = this.ages[0] === 18 && this.ages[1] >= 100 ? 101 : this.ages[1]
        apiUrlParameters += `&free_field_06=[${this.ages[0]},${this.ages[1]}]`
        this.order.targeting_filters["free_field_06"] = this.ages
      }
      if (this.loyalty_points[1] >= 1) {
        apiUrlParameters += `&free_field_07=[${this.loyalty_points[0]}, ${this.loyalty_points[1]}]`
        this.order.targeting_filters["free_field_07"] = this.loyalty_points
      }

      this.order.targeting_filters['query_parameters'] = `campaign_order=${this.$route.params.orderId}${apiUrlParameters}&store=${this.$entity.getId()}`;

      return apiUrlParameters;
    },
    toggleFreeFieldOne(element) {
      if (this.order.disabledAction) return;

      // Turn the element to active.
      this.$refs[element][0].classList.toggle("is-active")
      // If it is already in the list of selected items remove it.
      if(this.freeFieldOneSelected.find(e => element == e)){
        const index = this.freeFieldOneSelected.indexOf(element);
        if (index > -1) this.freeFieldOneSelected.splice(index, 1)
      } else {
        // If it isn't already in the list of selected items add it.
        this.freeFieldOneSelected = [...this.freeFieldOneSelected, element]
      }
    },
    toggleFreeFieldTwo(element) {
      if (this.order.disabledAction) return;

      // Turn the element to active.
      this.$refs[element][0].classList.toggle("is-active")
      // If it is already in the list of selected items remove it.
      if(this.freeFieldTwoSelected.find(e => element == e)){
        const index = this.freeFieldTwoSelected.indexOf(element);
        if (index > -1) this.freeFieldTwoSelected.splice(index, 1)
      } else {
        // If it isn't already in the list of selected items add it.
        this.freeFieldTwoSelected = [...this.freeFieldTwoSelected, element]
      }
    },
    reset(prepare=false) {
      // Remove the active mode for each element.
      Object.keys(this.$refs).map(e => this.$refs[e][0].classList.remove("is-active"))
      // Put default value to all props
      this.freeFieldOneSelected = [];
      this.freeFieldTwoSelected = [];
      this.checkboxChildren = '';
      this.checkboxGender = '';
      this.checkboxLivretSport = '';
      this.postal_codes = [];
      this.department = [];
      this.ages = [18, 100]
      this.loyalty_points = [0, 0]
      this.dates = []
      this.visitCount = [0, 0]
      this.gross_sales = [0, 0]
      this.selectedTypes = []
      this.selectedDomains = []
      this.selectedTrademark = []
    },
    resetBtn() {
      this.reset();
      this.order.targetNumber = 0;
      this.order.priceEstimation = 0;
      this.$buefy.snackbar.open({
        message: 'Tous les critères de ciblage ont été remis à zero',
        duration: 5000,
      });

      this.order.targeting_filters = {};
    },
    goToContent() {

      if(this.routeReached <= 1) {
        this.routeReached = 1;
      }

      // Save targeting filters.
      this.craftUrl()
    }
  }
}
