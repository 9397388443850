<template>
  <b-autocomplete
    v-model="campaignTitle"
    clearable
    icon="map-marked-alt"
    size="is-small"
    placeholder="Rechercher une campagne par son nom"
    :data="campaignsFiltered">
  </b-autocomplete>
</template>

<script>
export default {
  props: {
    campaigns: {
      default: () => ([]),
      type: Array,
    }
  },
  data () {
    return {
      data: [],
      campaignTitle: '',
      isLoading: false,
    }
  },
  computed: {
    campaignsFiltered() {
      if (this.campaignTitle.trim()) {
        this.isLoading = true;

        this.data = this.campaigns.filter(
          (option) => option.label
            .toString()
            .toLowerCase()
            .indexOf(this.campaignTitle.toLowerCase()) >= 0);

        this.isLoading = false;
      } else {
        this.data = this.campaigns;
      }

      this.$emit('update:data', this.data);
    }
  },
}
</script>
