<template>
  <div v-if="order">

    <b-field horizontal label="Nom de la commande">
      <b-input size="is-small" disabled expanded v-model="order.label"></b-input>
    </b-field>
    <b-field horizontal label="Description de la commande">
      <b-input size="is-small" disabled expanded v-model="order.description"></b-input>
    </b-field>
    <hr class="navbar-divider">
    <b-field horizontal label="Nom de la campagne">
      <b-input size="is-small" disabled expanded v-model="order.campaign.label"></b-input>
    </b-field>
    <b-field horizontal label="Nom de la diffusion">
      <b-input size="is-small" disabled expanded v-model="order.campaign.deliveries[0].label"></b-input>
    </b-field>
    <hr class="navbar-divider">
    <b-field horizontal label="Canal">
      <b-field class="icon-aligned" style="float: left;">
        <b-button
          disabled
          size="is-small"
          type="is-light"
          icon-pack="fas"
          :icon-left="this.$channel(order.channel)">
          {{ order.channel === 1 ? 'Email' : order.channel === 2 ? 'SMS' : 'Courier' }}
        </b-button>
      </b-field>
    </b-field>
    <b-field horizontal label="Nombre de contact ciblé">
      <template>
        {{ order.targetNumber + " contact" | pluralize(order.targetNumber) }}
      </template>
    </b-field>
    <b-field horizontal label="Budget">
      <template v-if="order.priceEstimation">
        <template v-if="order.channel === 2">
          {{ parseFloat(order.priceEstimation) * (Math.floor(order.content.customFieldValueLength / 161) + 1) | price }}
          <b-tooltip
            v-if="Math.floor(order.content.customFieldValueLength / 160)"
            multilined
            position="is-right"
            class="b-tooltip-custom"
            type="is-dark"
            size="is-large"
            :label="`Environ ${Math.floor(order.content.customFieldValueLength / 161) + 1} SMS vont être envoyés par contact du fait que votre SMS contient ${order.content.customFieldValueLength} caractères !`">
            <b-icon
              size="is-small"
              icon="info-circle"
              pack="fas"></b-icon>
          </b-tooltip>
        </template>
        <template v-else>
          {{ parseFloat(order.priceEstimation) | price }}
        </template>
      </template>
      <template v-else>
        0,00€
      </template>
    </b-field>

    <b-field horizontal label="Date d'envoi">
      <b-field grouped>
        <b-field>
          <b-datepicker
            size="is-small"
            placeholder="Sélectionner une date"
            icon-pack="fas"
            :disabled="lockDate || order.disabledAction"
            :min-date="minDate"
            :unselectable-dates="unselectableDates"
            :unselectable-days-of-week="[0]"
            v-model="date">
          </b-datepicker>

          <b-select
            v-model="time"
            placeholder="Heure d'envoi"
            size="is-small"
            :disabled="lockDate || order.disabledAction">
            <option
              v-for="nmb in hoursByDay"
              v-if="disabledHours(nmb)"
              :value="nmb"
              :key="nmb">
              {{ nmb }} h
            </option>
          </b-select>
        </b-field>

        <b-switch
          :disabled="order.disabledAction"
          class="is-small"
          v-model="lockDate"
          type="is-info">
          Envoi immédiat
        </b-switch>
      </b-field>
    </b-field>

    <br>

    <b-message
      v-if="alertSMS"
      size="is-small"
      type="is-danger">
      <b-icon icon="exclamation-triangle"></b-icon>
      Une campagne nationale est déjà programmé pour ce jour.

	    <br>

      <template v-if="order.channel === 1">
	      Rappel de la règle sur la pression commerciale = 1 mail par jour
      </template>

      <template v-else-if="order.channel === 2">
	      Rappel de la règle sur la pression commerciale = 1 SMS par jour
      </template>
    </b-message>

    <b-notification
      type="is-warning is-light"
      :closable="false"
      role="alert">
      <b-icon icon="exclamation-circle" size="is-small"></b-icon>
      L'heure sélectionnée ci-dessus correspond à une plage horaire d'une heure. L'envoi de cette campagne email/SMS sera déclenchée durant cette plage horaire.

      <br><br>Exemple : Pour une campagne programmée pour 15H, l'envoi sera réalisée entre 15H et 15H59.
    </b-notification>

    <br>

    <template v-if="[1, 2, 3].includes(this.order.status) && this.$moment(this.order.schedule).diff(this.$moment(), 'minutes') > this.maxLimitDate && this.order.disabledAction">
      <b-field
        grouped
        class="is-pulled-right">
        <p class="control">
          <cancelOrder
            compType="extend"
            :order="order"
            :isFetchLoading="isFetchLoading">

          </cancelOrder>
        </p>
      </b-field>
    </template>

    <b-button
      v-if="!order.disabledAction"
      type="is-twitter"
      class="is-pulled-right"
      size="is-small"
      :loading="isFetchLoading"
      :disabled="isFetchLoading"
      @click="validCampaign">
      {{ reviewMode ? 'Envoyer' : 'Soumettre pour validation' }}
    </b-button>
    <br><br>
  </div>
</template>

<script>
const today = new Date();
const formatSchedule = 'YYYY-MM-DD HH:mm:ss';

import cancelOrder from "@/components/Order/cancelOrder";

export default {
  props: ['order'],
  components: {
    cancelOrder
  },
  data() {
    return {
      alertSMS: false,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      date: new Date(),
      time: today.getHours()+1,
      lockDate: false,
      reviewMode: false,
      finalMessage: '',
      isFetchLoading: false,
      isOkMessageAppear: false,
      cancellationMessage: 'Vous ne pouvez plus annuler cette commande. Celle-ci est est en cours de routage afin d\'être envoyée !',
      maxLimitDate: 61,
      hoursByDay: Array(24 - 1 + 1).fill().map(
        (_, idx) => idx >= 9 ? String(1 + idx) : '0' + parseInt(1 + idx)
      ),
    }
  },
  watch: {
    date(event) {
      this.checkAlertSMS(event)
    },
    lockDate(isLock) {
      if (isLock) {
        this.date = new Date();
        this.time = new Date().getHours()+1;
      }
    }
  },
  async created() {
    this.reviewMode = this.order.status === 3 || this.order.campaign.campaign_type === 3;
    this.finalMessage = this.reviewMode == false ? 'Votre commande a été envoyée !' : 'Commande validée !';
    if(this.order.schedule && this.order.schedule != '2000-01-01T00:00:01+01:00' && this.order.schedule != "Aucune date sélectionnée") {
      this.date = new Date(this.$moment(this.order.schedule));
      this.time = this.$moment(this.date).format('HH');
    }
    else {
      this.date = new Date(this.$moment(this.$moment().format('YYYY-MM-DD HH')).add(2, 'days'));
      let datePrepare = this.$moment(this.date).add(1, 'hours');
      this.time = this.$moment(datePrepare).format('HH');
    }
  },
  beforeMount() {
    if (!this.order.campaign) this.$router.push({name: 'Catalog'})
  },
  mounted() {
    this.$me.put(`/campaigns-orders/update-campaign-order/${this.$route.params.orderId}/`,
      {content: this.order.content},
      {params:{storeFilter: false}})
  },
  methods: {
    checkAlertSMS(date) {
      if(!this.$store.state.alertSMS) {
        this.alertSMS = false
        return false
      }
      const checkRows = obj => obj.begin_date === this.$moment(date).format('YYYY-MM-DD') && obj.channel === this.order.channel
      this.alertSMS = this.$store.state.alertSMS.some(checkRows)
    },
    unselectableDates(day) {
      /**
       *
       * @day {date}
       */
      const dateWithoutYear = this.$moment(day).format('DD/MM'),
        dateWithYear = this.$moment(day).format('DD/MM/YYYY'),
        publicHolidays = ['14/07', '15/08', '01/11', '11/11', '25/12'],
        specificDays = ['26/05/2022', '18/05/2023', '09/05/2024']
      return publicHolidays.includes(dateWithoutYear) || specificDays.includes(dateWithYear)
    },
    disabledHours(nmb) {
      /**
       *
       * @nmb {string}
       */
      nmb = parseInt(nmb)
      let dt = this.$moment(this.date).format('YYYY-MM-DD 00')
      return nmb >= 8 && nmb < 20 && this.$moment(dt, 'YYYY-MM-DD HH')
        .add(nmb, 'hours')
        .isAfter(this.$moment().format())
    },
    validCampaign() {
      this.$buefy.dialog.confirm({
        message: 'Êtes-vous sûr de vouloir continuer ?',
        type: 'is-twitter',
        size: 'is-small',
        confirmText: 'Continuer',
        cancelText: 'Annuler',
        onConfirm: async () => {
          this.isFetchLoading = true;

          await this.$me.put(`/campaigns-orders/update-campaign-order/${this.$route.params.orderId}/`,
            {
              schedule: this.$moment(`${this.$moment(this.date).format('YYYY-MM-DD')} ${this.$moment(`1990-01-01 ${this.time}`, 'YYYY-MM-DD HH').format('HH:mm:ss')}`, formatSchedule).format(formatSchedule),
              status: this.order.campaign.campaign_type === 4 ? 3 : 1,
              label: this.order.label,
              description: this.order.description,
              targeting_filters: this.order.targeting_filters,
              content: this.order.content
            },
            {params:{storeFilter: false}}
          )
            .then((res) => {
              this.isOkMessageAppear = true
              this.isFetchLoading = false;
            })
            .catch((err) => {
              this.isOkMessageAppear = false
              this.isFetchLoading = false;
              this.$buefy.toast.open({
                duration: 5000,
                message: `Une erreur est survenue, veuillez contacter un administrateur.`,
                position: 'is-bottom',
                type: 'is-danger'
              });
            });

          if (this.reviewMode) {
            this.isFetchLoading = true;

            await this.$me.post(`/campaigns-orders/validate-targeting/`,
              {
                store: this.order.store.id,
                campaign_order: parseInt(this.$route.params.orderId)
              },
              {params:{storeFilter: false}}
            )
              .then((res) => {
                this.isOkMessageAppear = true;
                this.isFetchLoading = false;
              })
            .catch((err) => {
              this.isOkMessageAppear = false
              this.isFetchLoading = false;
              this.$buefy.toast.open({
                duration: 5000,
                message: `Une erreur est survenue, veuillez contacter un administrateur.`,
                position: 'is-bottom',
                type: 'is-danger'
              });
            })
          }

          if (this.isOkMessageAppear){
            this.$buefy.snackbar.open({
              message: this.finalMessage,
              type: 'is-success',
              position: 'is-top',
              duration: 5000,
              actionText: 'Ok',
            });

            this.$router.push({name: 'CommandesAdherant'})

          } else
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Une erreur est survenue pendant la validation, veuillez contacter votre administrateur.',
              position: 'is-bottom',
              type: 'is-danger'
            })
        }
      });
    }
  }
}
</script>
