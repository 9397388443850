import { utils } from './index';

import {textCancellationMessage, varMaxLimitDate, textOrderAlreadySent, textCancelOrder} from '@/utils/variables.json';


export const order = {
  checkValidityOrder(order) {
    let validationDate = utils.diffDateWithNow(order.schedule, 'minutes');

    if(order.status === 1 && validationDate > 0 && validationDate <= varMaxLimitDate) {
      return {
        label: textCancellationMessage,
        type: 'is-danger',
        size: 'is-large',
        disabled: true
      }
    } else if([1].includes(order.status) && validationDate <= 0) {
      return {
        label: textOrderAlreadySent,
        type: 'is-dark',
        size: 'is-small',
        disabled: true,
      }
    } else {
      return {
        label: textCancelOrder,
        type: 'is-dark',
        size: 'is-small',
        disabled: false,
      }
    }
  },
}
