<template>
  <div>
    <div v-if="order.channel === 2" class="columns">
      <div class="column">
        <b-field
          label="Contenu du SMS"
          :message="[this.customFieldValueLength > 160 - (this.tagName ? 20 : 0) ? (tagName ? 'Attention ! En utilisant les balises <prenom> ou <nom>, il se peut que votre SMS peut dépasser les 160 caractères ce qui peut engendrer qu\'un deuxième SMS vous sera facturé pour chaque contact ciblé ayant un prénom ou nom avec plus de caractères que la moyenne.' : 'Attention ! Votre SMS dépasse les 160 caractères, votre facture SMS sera doublée !') : '']"
          :type="{ 'is-warning': this.customFieldValueLength > 160 - (this.tagName ? 20 : 0) }">

          <b-input
            :disabled="order.disabledAction"
            custom-class="textarea-padding"
            v-model="customFieldValue"
            type="textarea"
            size="is-small">
          </b-input>
        </b-field>

        <br>

        <div v-if="customFieldValue.length >= 0" :class="{'textRed': this.$store.state.action.disableEstimateButton}">
          <template v-if="customFieldValue.length > 0">
            <p class="title is-6">Voici un apercu du SMS :</p>
            <p class="subtitle contentSMS" v-text="parseSMS(customFieldValue)"></p>
            <p>
              <b-icon :icon="[$store.state.action.disableEstimateButton ? 'triangle-exclamation' : 'circle-check'].toString()" size="is-small"></b-icon>
              Votre SMS contient
              <span v-if="tagName" style="text-decoration: underline dotted">
                <b-tooltip
                  style="cursor: pointer;"
                  type="is-dark"
                  size="is-large"
                  label="Vous utilisez une balise <nom> ou <prenom>, le nombre de caractères de votre SMS peut varier pour chaque contact ciblé en fonction de son nom ou prénom pour chaque balise utilisée."
                  multilined>
                  environ
                </b-tooltip>
              </span>
              <b>&nbsp;{{ customFieldValueLength }} / 160 caractères maximum</b>
            </p>
          </template>

          <br>
          <p>Attention : les caractères suivants comptent pour 2 caractères mais sont aussi supportés par l’alphabet GSM : ^, {, }, \, [, ~, ], |, €</p><br>

          <p>Les caractères emojis (😀👍🏻..) ne sont pas acceptés !</p>
          <p>Caractères non pris en compte : ; (point-virgule)</p>

          <br>

          <p>La liste de caractères suivants compose cet alphabet GSM :
              @, £, $, ¥, è, é, ù, ì, ò, Ç, \n, Ø, ø, \r, Å, å, _, \f, ^, {, }, \, [, ~, ], |, €, Æ, æ, ß, É, , !, ", #, ¤, , x%x, ', (, ), *, +, , , -, ., /, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, :, <, =, >, ?, ¡, A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Y, Z, Ä, Ö, Ñ, Ü, §, ¿, a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z, ä, ö, ñ, ü, à
          </p>
        </div>
      </div>

      <div class="column">
        <p class="subtitle is-6 has-text-weight-bold">Infos</p>

        <p class="is-size-7">L'envoi de SMS/Email publicitaire/commercial est autorisé suivant les opérateurs entre 8H00
          et
          20H00 ou entre 10H00 et 20H00 tous les jours sauf le dimanche et les jours fériés.
          En cas de modification du contenu SMS/email mis à disposition par Sport 2000, et sans demande de validation
          auprès
          de nos services, Sport 2000 ne sera pas responsable de la validité légale du nouveau contenu du SMS/email
          produit.
          La validation de la commande du SMS/email sur l'écran fera l'objet de l'envoi automatique d'un email au
          PARTENAIRE
          confirmant sa prise en compte de la commande, ses caractéristiques et le prix en euros HT. La facture sera
          envoyée
          par le service comptabilité de Sport 2000.
        </p>

        <br>
        <p class="subtitle is-7 has-text-weight-bold">Votre libellé magasin optimisé</p>
        <p>Pour votre magasin {{ order.store.title }} pensez à optimiser le nom de votre magasin pour gagner des caractères.</p>

                  <p class="subtitle is-7 has-text-weight-bold">Afin de personnaliser les SMS, utilisez les balises suivantes
          :</p>
        <ul>
<!--          <li class="is-size-7">&lt;nom&gt; : <b>Nom de famille</b> du contact ciblé <br> - soit environ 10 caractères</li>-->
<!--          <li class="is-size-7">&lt;prenom&gt; : <b>Prénom</b> du contact ciblé <br> - soit environ 7 caractères</li>-->
          <li class="is-size-7">&lt;magasin&gt; : <b>{{ order.store.title }}</b> (nom de votre magasin)<br> - soit
            {{ order.store.title.length }} caractères
          </li>
        </ul>

        <br>
        <template>
          <br>
          <p class="subtitle is-7 has-text-weight-bold">Informations supplémentaires :</p>
          <ul class="is-size-7">
            <li>- Ne pas rajouter la mention légale "Cond mag Stop 36200" car celle-ci est déjà par défaut sur 20
              caractères.
            </li>
            <li>- Un SMS peut contenir jusqu'à 160 caractères.
            </li>
            <li>-Si vous avez besoin d’aider pour rédiger ou synthétiser votre message contacter le service fidélité.</li>
<!--            <li>- Le nombre de caractères de votre SMS peut varier à l'envoi si vous utilisez les balises &lt;nom&gt; ou-->
<!--              &lt;prenom&gt;-->
<!--            </li>-->
          </ul>
        </template>
      </div>

    </div>
    <!-- EMAIL -->
    <div v-else class="columns">
      <div class="column is-4">
        <div class="is-fixed custom-sticky">
          <h1 class="title is-5">Champs de personnalisation</h1>
          <b-field v-if="order.content.custom_field_label_1">
            <template #label>
              <b-icon icon="text"></b-icon>
              {{ order.content.custom_field_label_1 }}
            </template>

            <b-input
              :disabled="order.disabledAction"
              v-model="order.content.custom_field_value_1"
              :placeholder="order.content.custom_field_label_1"
              size="is-size-7"
              type="textarea"
              maxlength="160">
            </b-input>
          </b-field>

          <b-field v-if="order.content.custom_field_label_2">
            <template #label>
              <b-icon icon="text"></b-icon>
              {{ order.content.custom_field_label_2 }}
            </template>

            <b-input
              :disabled="order.disabledAction"
              v-model="order.content.custom_field_value_2"
              :placeholder="order.content.custom_field_label_2"
              size="is-size-7"
              type="textarea"
              maxlength="200">
            </b-input>
          </b-field>

          <b-field v-if="order.content.custom_field_label_3">
            <template #label>
              <b-icon icon="text"></b-icon>
              {{ order.content.custom_field_label_3 }}
            </template>

            <b-input
              :disabled="order.disabledAction"
              v-model="order.content.custom_field_value_3"
              :placeholder="order.content.custom_field_label_3"
              size="is-size-7"
              type="textarea"
              maxlength="200">
            </b-input>
          </b-field>

          <b-field v-if="order.content.custom_field_label_4">
            <template #label>
              <b-icon icon="text"></b-icon>
              {{ order.content.custom_field_label_4 }}
            </template>

            <b-input
              :disabled="order.disabledAction"
              v-model="order.content.custom_field_value_4"
              :placeholder="order.content.custom_field_label_4"
              size="is-size-7"
              type="textarea"
              maxlength="200">
            </b-input>
          </b-field>

          <b-field v-if="order.content.custom_field_label_5">
            <template #label>
              <b-icon icon="text"></b-icon>
              {{ order.content.custom_field_label_5 }}
            </template>

            <b-input
              :disabled="order.disabledAction"
              v-model="order.content.custom_field_value_5"
              :placeholder="order.content.custom_field_label_5"
              size="is-size-7"
              type="textarea"
              maxlength="200">
            </b-input>
          </b-field>
        </div>

        <br>
      </div>
      <div class="column has-text-centered">
        <img class="thumbnail" :src="order.campaign.deliveries[0].thumbnail" @click="openPreview = !openPreview">
        <b-modal :active.sync="openPreview" class="model-custom">
          <div class="model-content-center">
            <img :src="order.campaign.deliveries[0].thumbnail" alt="">
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>



<script>
// import replaceSpecialCharacters from 'replace-special-characters'


export default {
  props: ['order'],
  data() {
    return {
      openPreview: false,
      reviewMode: false,
      srcImage: '',
      customFieldEventMessage: '',
      condSMS: ' Cond mag STOP 36200',
      customFieldValueLength: 0,
      tagName: false,
      // customFieldValue: String(this.order.content.custom_field_value_1) || '',
      customFieldValue: '',
    }
  },
  beforeMount() {
    this.customFieldValue = this.order.content.custom_field_value_1.length === 0 ?
      (
        this.order.campaign.id.indexOf("AvecValidation") ?
        this.order.catalog.filter(el => el.title === this.order.label)[0].content :
        ""
      ) :
      this.order.content.custom_field_value_1
  },
  mounted() {
    if (this.customFieldValue)
      this.$store.state.action.disabledBContentButton = this.customFieldValue.length <= 15 && this.order.channel === 2
    this.calcCharactersInInput(this.transformCharacters(this.order.content.custom_field_value_1 || ''))
  },
  watch: {
    customFieldValue(event) {
      this.$store.state.action.disabledBContentButton = event.length <= 15 && this.order.channel === 2
      if(event.length <= 180)
        this.order.content.custom_field_value_1 = this.transformCharacters(event).slice(0, 180);
      // Calculate the number of characters in the input
      this.calcCharactersInInput(this.transformCharacters(event))
    }
  },
  methods: {
    calcCharactersInInput(content) {
      /**
       * @param {String} content String characters
       */
      let ctr = content.length + this.condSMS.length + this.countSpecialCharacters(content)
      this.customFieldValueLength = ctr
      this.order.content.customFieldValueLength = ctr
      this.$store.state.action.disableEstimateButton = ctr > 160
    },
    transformCharacters(content) {
      /**
       * @param {String} content String characters
       */
      content = this.replaceStore(content)
      content = this.removeSpecialCharacters(content)
      content = this.replaceSpecialCharacters(content)
      content = this.removeExcessSpaces(content)
      return content || ''
    },
    removeExcessSpaces(content) {
      /**
       * @param {String} content String characters
       * @return {String} Return a string with remove excess spaces
       */
      return String(content).replace(/\s+/g, ' ').trim();
    },
    removeSpecialCharacters(content) {
      /**
       * @param {String} content String characters
       * @return {String} Return a string with only the characters in the replace function
       */
      return String(content)
        .replace(/[^a-z0-9A-Z çàùôêéèàâï!?<>^{}~\]\[|€&%*`'"(),:./+=_-]/gi,'')
        .replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
    },
    replaceSpecialCharacters(content) {
      /**
       * @param {String} content String characters
       * @return {String} Returns a character string with replaced characters
       */
      return String(content)
        .replace('…','...')
        .replace('‘', "'")
        .replace('’', "'")
        .replace('”', '"')
        .replace('“', '"')
        .replace(';', '"')
        .replace(';', '')
    },
    countSpecialCharacters(content) {
      /**
       * @param {String} content String characters
       * @return {Number} Returns the number of characters in the string
       */
      return [...String(content)].filter(x => "^,{,},[,~,],|,€".split(",").includes(x)).length || 0
    },
    replaceStore(content) {
      /**
       * @param {String} content String characters
       */
      return content.replace('<magasin>', this.order.store.title)
    },
    parseSMS(content) {
      /*
      Prepare the preview of the content of an SMS
      content: String content
      */
      // Define content variable in str
      if(!content) content = ''
      // Check existing list tags
      // this.tagName = ['<nom>', '<prenom>'].some(i => content.toLowerCase().includes(i))
      // content = content.replace('<nom>', this.$store.state.family_name || 'NOMDEFAMILLE')
      // content = content.replace('<prenom>', this.$store.state.given_name || 'PRENOM')
      // Replace the <magasin> tag with the name of the connected store
      content = this.replaceStore(content)
      // Remove all excess spaces
      content = this.removeExcessSpaces(content)
      // Allow only characters in searchValue
      content = this.removeSpecialCharacters(content)
      content = this.replaceSpecialCharacters(content)
      // Add to unsubscribe conditions at the end of the content variable
      return content.slice(0, 160 - this.condSMS.length) + this.condSMS
    }
  }
}
</script>

<style>
img.thumbnail {
  background-position: center;
  max-width: 100%;
  max-height: 100vh;
  margin: 0 auto;
  cursor: pointer;
}

.custom-sticky {
  position: sticky;
  top: 50px;
}

.model-content-center {
  display: flex;
  justify-content: center;
}

.textarea-padding {
  padding-right: 50px!important;
}

.contentSMS {
  font-size: 1.2em;
  background-color: #edeff0;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}

.textRed {
  color: red;
  font-weight: bold;
}
</style>
