<template>
  <div>
    <b-button
      v-if="this.compType === 'extend'"
      @click="cancelOrder(order)"
      type="is-danger"
      size="is-small"
      :loading="isFetchLoading"
      :disabled="isFetchLoading">
      Annuler la commande
    </b-button>

    <b-button
      v-else-if="this.compType === 'simple'"
      :disabled="btnDisabled"
      size="is-small"
      type="is-danger"
      icon-right="window-close"
      icon-pack="fas"
      @click="() => cancelOrder(order)">

    </b-button>
  </div>
</template>

<script>
import { textCancellationMessage } from '@/utils/variables.json';
import { order as orderUtils } from '@/utils/order';

export default {
  props: ['compType', 'order', 'isFetchLoading', 'orders'],
  data () {
    return {
      btnDisabled: orderUtils.checkValidityOrder(this.order).disabled
    }
  },
  methods: {
    cancelOrder (order) {
      if(!orderUtils.checkValidityOrder(order).disabled || order.status !== 1) {
        this.$buefy.dialog.confirm({
          message: 'Êtes-vous sûr de vouloir annuler la commande ?',
          type: 'is-twitter',
          size: 'is-small',
          confirmText: 'Confirmer l\'annulation de la campagne',
          cancelText: 'Annuler',
          onConfirm: async () => {
            this.$me.put(`/campaigns-orders/update-campaign-order/${order.id}/`, {
              status: 0
            })
              .catch((err) => {
                this.$buefy.toast.open({
                  duration: 5000,
                  message: 'Une erreur est survenue pendant l\'annulation, veuillez contacter votre administrateur.',
                  position: 'is-bottom',
                  type: 'is-danger'
                })
              })
              .then((res) => {
                if(this.orders) {
                  this.orders[this.orders.indexOf(order)].status = 0;

                  this.$emit('updateOrders', this.orders)
                } else {
                  this.$router.push({
                    name: 'CommandesAdherant'
                  });
                }

                this.$buefy.toast.open({
                  duration: 5000,
                  message: 'Commande annulée',
                  position: 'is-bottom',
                  type: 'is-info'
                });
              });
          }
        });
      } else {
        this.$buefy.dialog.alert({
          message: textCancellationMessage,
          size: 'is-small',
          type: 'is-warning',
          confirmText: 'Ok'
        });
      }
    },
  }
}
</script>
