<template>
  <div style="height: 85vh" v-if="order">
    <h1 class="title is-5">{{ order.campaign.deliveries[0].label }}</h1>
    <h1 class="subtitle is-6">{{ order.campaign.deliveries[0].description }}</h1>

    <div class="columns">
      <div class="column is-2">
        <div class="box" id="nav-progress">
          <b-steps
            label-position="bottom"
            size="is-small"
            type="is-info"
            :rounded="false"
            has-navigation
            vertical
            v-model="activeStep">

            <template #navigation="{previous, next}">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <template v-if="order.disabledAction">
                    <b-button
                      expanded
                      size="is-small"
                      type="is-twitter"
                      :disabled="previous.disabled"
                      @click.prevent="previous.action">
                      Précédent
                    </b-button>

                    <b-button
                      expanded
                      size="is-small"
                      type="is-twitter"
                      :disabled="next.disabled"
                      @click.prevent="next.action">
                      Suivant
                    </b-button>
                  </template>

                  <template v-else>
                    <b-button
                      expanded
                      size="is-small"
                      type="is-twitter"
                      :disabled="previous.disabled || $store.state.action.disableEstimateButton || activeStep === 2 && $store.state.action.disabledBContentButton"
                      @click="updateOrder"
                      @click.prevent="previous.action">
                      Enregistrer et précédent
                    </b-button>

                    <b-button
                      expanded
                      size="is-small"
                      type="is-twitter"
                      :disabled="next.disabled || $store.state.action.disableEstimateButton || activeStep === 2 && $store.state.action.disabledBContentButton || ['Campagne sans validation', 'Campagne avec validation'].includes(order.label)"
                      @click="updateOrder"
                      @click.prevent="next.action">
                      Enregistrer et suivant
                    </b-button>

<!--                    <b-button-->
<!--                      expanded-->
<!--                      size="is-small"-->
<!--                      type="is-twitter"-->
<!--                      :disabled="next.disabled || $store.state.action.disableEstimateButton || activeStep === 2 && $store.state.action.disabledBContentButton"-->
<!--                      @click="updateOrder"-->
<!--                      @click.prevent="next.action">-->
<!--                      Enregistrer et suivant-->
<!--                    </b-button>-->
                  </template>
                </div>
              </div>
            </template>

            <b-step-item icon="text-size" label="Description" :clickable="routeReached >= 0 || order.disabledAction" />

            <b-step-item icon="bullseye-pointer" label="Ciblage client" :clickable="routeReached >= 1 || order.disabledAction" />

            <b-step-item icon="align-justify" label="Contenu" :clickable="routeReached >= 2 || order.disabledAction" />

            <b-step-item icon="check" label="Exécution" :clickable="routeReached >= 3 || order.disabledAction" />
          </b-steps>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <template v-if="!isLoading && order">
            <OrderCampaign v-if="activeStep === 0" :order="order" />

            <OrderTarget v-if="activeStep === 1" :order="order" />

            <OrderContent v-if="activeStep === 2" :order="order" />

            <OrderComplete v-if="activeStep === 3" :order="order" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderCampaign from "@/views/Campaign/Campaign";
import OrderTarget from "@/views/Campaign/Target.vue";
import OrderContent from "@/views/Campaign/Content";
import OrderComplete from "@/views/Campaign/Complet";

export default {
  components: {
    OrderCampaign,
    OrderTarget,
    OrderContent,
    OrderComplete,
  },
  data() {
    return {
      isLoading: false,
      btnDisabled: false,
      activeTitle: 'Commander',
      routeReached: 0,
      activeStep: 0,
      order: false,
      routes: [
        {
          id: 0,
          title: 'Description',
          view: 'Campaign',
          icon: 'text-size',
        },
        {
          id: 1,
          title: 'Ciblage client',
          view: 'Target',
          icon: '',
        },
        {
          id: 2,
          title: 'Contenu',
          view: 'Content',
          icon: '',
        },
        {
          id: 3,
          title: 'Exécution',
          view: 'Complet',
          icon: '',
        },
      ]
    }
  },
  methods: {
    preparePayload: function(order) {
      if(this.activeStep === 0) {
        if(this.routeReached === 0) this.routeReached = 1;

        return {
          label: order.label.toString().trim(),
          description: order.description.toString().trim(),
        }
      } else if(this.activeStep === 1) {
        if(this.routeReached === 1) this.routeReached = 2;

        return {
          targeting_filters: order.targeting_filters
        }
      } else if(this.activeStep === 2) {
        if(this.routeReached === 2) this.routeReached = 3;

        return {
          content: order.content
        }
      } else if(this.activeStep === 3) {
      }
    },
    updateOrder: function() {
      this.isLoading = true;

      if(this.order.content && this.order.channel === 2) {
        this.order.content.custom_field_value_1 = this.removeSpecialCharacters(
          this.removeExcessSpaces(this.order.content.custom_field_value_1)
        );
      }

      this.$me.put(`/campaigns-orders/update-campaign-order/${this.$route.params.orderId}/`,
        this.preparePayload(this.order),
        {params:{storeFilter: false}}
      )
        .then(() => {
          this.$buefy.toast.open({
            message: 'Commande enregistrée',
            type: 'is-success',
            queue: false,
            position: 'is-bottom-left',
            duration: 5000,
          })
        })
        .finally(() => this.isLoading = false);
    },

    removeExcessSpaces(content) {
      /**
       * @param {String} content String characters
       * @return {String} Return a string with remove excess spaces
       */
      return String(content).replace(/\s+/g, ' ').trim();
    },
    removeSpecialCharacters(content) {
      /**
       * @param {String} content String characters
       * @return {String} Return a string with only the characters in the replace function
       */
      return content.replace(/[^a-z0-9A-Z çàùôêéèàâï!?<>€&%*`'"(),:;./+=_-]/gi,'')
    },
    replaceSpecialCharacters(content) {
      /**
       * @param {String} content String characters
       * @return {String} Returns a character string with replaced characters
       */
      return content
        .replace('…','...')
        .replace('‘', "'")
        .replace('’', "'")
        .replace('”', '"')
        .replace('“', '"')
    },
    prepareCampaign(order) {
      this.order = order;
      if(order.hasOwnProperty('store')) {
        this.$cx.get(`/stores/get-store/${order.store.id}`)
          .then(({title}) => {
            this.order.store['title'] = title || '';
          });
      }

      this.order.disabledAction = [0, 1].includes(this.order.status) || this.$route.query.action === 'show' || false;

      if(this.$store.state.permission <= 30 && parseInt(order.store.id) !== parseInt(this.$entity.getId())) {
        this.$router.push({name: 'CommandesAdherant'});

        this.$buefy.dialog.alert({
          message: 'Cette commande est introuvable !',
          size: 'is-small',
          type: 'is-danger',
          ariaRole: 'alertdialog',
          ariaModal: true,
        });

        this.isLoading = false;

        return;
      }

      this.order.channel = order.campaign.deliveries[0].channel;
      this.order.targetNumber = 0;
      this.order.priceEstimation = 0;

      if(!order.targeting_filters) {
        this.order.targeting_filters = {};
      }

      this.order.saveTargeting = []

      if (!this.order.content) this.order.content = {};

      if (!this.order.content.custom_field_label_1)
        this.order.content.custom_field_label_1 = this.order.campaign.deliveries[0].custom_field_label_1 || '';
      if (!this.order.content.custom_field_label_2)
        this.order.content.custom_field_label_2 = this.order.campaign.deliveries[0].custom_field_label_2 || '';
      if (!this.order.content.custom_field_label_3)
        this.order.content.custom_field_label_3 = this.order.campaign.deliveries[0].custom_field_label_3 || '';
      if (!this.order.content.custom_field_label_4)
        this.order.content.custom_field_label_4 = this.order.campaign.deliveries[0].custom_field_label_4 || '';
      if (!this.order.content.custom_field_label_5)
        this.order.content.custom_field_label_5 = this.order.campaign.deliveries[0].custom_field_label_5 || '';

      if (!this.order.content.custom_field_value_1)
        this.order.content.custom_field_value_1 = this.order.campaign.deliveries[0].custom_field_value_1 || '';
      if (!this.order.content.custom_field_value_2)
        this.order.content.custom_field_value_2 = this.order.campaign.deliveries[0].custom_field_value_2 || '';
      if (!this.order.content.custom_field_value_3)
        this.order.content.custom_field_value_3 = this.order.campaign.deliveries[0].custom_field_value_3 || '';
      if (!this.order.content.custom_field_value_4)
        this.order.content.custom_field_value_4 = this.order.campaign.deliveries[0].custom_field_value_4 || '';
      if (!this.order.content.custom_field_value_5)
        this.order.content.custom_field_value_5 = this.order.campaign.deliveries[0].custom_field_value_5 || '';
    }
  },
  watch: {
    activeStep: function(step) {
      let swit = this.routes.find(r => r.id === step);

      this.activeTitle = swit.title;
      this.activeStep = swit.id;
    },
  },
  mounted () {
    this.$store.state.openMenu = false;

    this.isLoading = true;

    this.$me.get(`/campaigns-orders/get-campaign-order/${this.$route.params.orderId}/`, {params:{storeFilter: false}})
      .then(order => {
        this.prepareCampaign(order)
      })
      .catch((err) => {
        this.$buefy.dialog.alert({
          message: 'Cette commande est introuvable ! #1',
          size: 'is-small',
          type: 'is-danger',
          ariaRole: 'alertdialog',
          ariaModal: true,
          onConfirm: () => {
            this.$router.push({name: 'CommandesAdherant'});
          }
        });
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  beforeCreate() {
    if (!this.$route.params.orderId)
      this.$router.push({ name: 'Catalog' })
  },
}
</script>

<style>
#nav-progress {
//padding: 1.25rem;
//border-radius: 6px;
//-webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
//box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
//background: white;
  position: sticky;
  top: 25px;
  z-index: 5;
}

.steps {
  justify-content: center;
}
.step-content {
  display: none;
}
.step-navigation {
}

.step-details {
  padding: 5px;
  background-color: white!important;
}

#nav-progress div.b-steps {
  justify-content: center;
}

#nav-progress nav.steps {
  width: 100%;
}

#nav-progress .step-content {
  overflow: hidden;
  width: 0;
  height: 0;
  padding: 0;
}
</style>
