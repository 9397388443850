<template>
  <div v-if="order">
    <b-field horizontal
             expanded
             label="" v-if="order.channel !== 2 || order.label == 'Campagne sans validation'" size="is-small">
      <b-message type="is-warning" size="is-small">Pour passer à l'étape suivante, merci de changer le nom de la commande</b-message>
    </b-field>
    <b-field
      horizontal
      expanded
      label="Nom de la commande">
<!--      <b-input-->
<!--        :loading="isFetchLoading"-->
<!--        :disabled="isFetchLoading || order.disabledAction"-->
<!--        maxlength="100"-->
<!--        type="is-info"-->
<!--        placeholder="Nom personnalisé de votre campagne"-->
<!--        v-model="order.label"-->
<!--        size="is-small"-->
<!--        expanded-->
<!--        required>-->
<!--      </b-input>-->
      <template v-if="order.channel !== 2 || order.label != 'Campagne avec validation'">
        <b-field>
          <b-input
            :loading="isFetchLoading"
            :disabled="isFetchLoading || order.disabledAction"
            maxlength="100"
            type="is-info"
            placeholder="Nom personnalisé de votre campagne"
            v-model="order.label"
            size="is-small"
            expanded
            required>
          </b-input>
        </b-field>
        <br>
      </template>
      <template v-else>
        <b-field :type="{'is-dark': ['Campagne avec validation'].includes(order.label)}">
          <b-select
            :loading="isFetchLoading || !catalog.length"
            v-model="order.label"
            size="is-small"
            expanded
            required>
            <option
              v-for="option in catalog"
              :value="option.title"
              :key="option.title">
              {{ option.title }}
            </option>
          </b-select>
        </b-field>
      </template>
    </b-field>

    <b-field
      horizontal
      expanded
      label="Canal">
      <b-button
        disabled
        size="is-small"
        type="is-light"
        :loading="isFetchLoading"
        icon-pack="fas"
        :icon-left="this.$channel(order.channel)">
        {{ order.channel === 1 ? 'Email' : order.channel === 2 ? 'SMS' : 'Courier' }}
      </b-button>
    </b-field>

    <b-field
      horizontal
      expanded
      label="Description">
      <b-input
        :loading="isFetchLoading"
        :disabled="isFetchLoading || order.disabledAction"
        v-model="order.description"
        type="textarea"
        size="is-small"
        placeholder="Description personnalisé de votre campagne"
        expanded>
      </b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  props: ['order'],
  data () {
    return {
      isFetchLoading: false,
      selected: null,
      catalog: [],
    }
  },
  mounted() {
    if(this.order.channel === 2) {
      console.log("e")
      this.$me.get('campaign-catalog-list/?store=' + this.$entity.getId())
        .then((content) => {
          console.log(content)
          this.catalog = content
        })
        .finally(() => {
          this.order.catalog = this.catalog
        })
    }

    this.$me.get('campaigns-catalog-limit/')
      .then(({data}) => {
        this.$store.state.alertSMS = data
      })
      .catch(() => {
        this.$store.state.alertSMS = false
      })
  }
}
</script>
