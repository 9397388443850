<template>
  <section class="section">
    <div class="container">
      <template v-if="$route.meta.master === true">
        <b-message title="Campagnes locales" :closable="false" class="bcw is-size-7" type="is-primary">
          <b-message
            size="is-small"
            type="is-warning">
            <b-icon icon="exclamation-triangle"></b-icon>
            Ne pas planifier des campagnes locales le jour des campagnes nationales.
          </b-message>

          <b-message
            v-if="$store.state.blockSMS"
            size="is-small"
            type="is-danger">
            <b-icon icon="cart-circle-xmark"></b-icon>
            Vous ne pouvez plus commander de campagnes SMS. Veuillez contacter un responsable Sport 2000 pour débloquer la situation.
          </b-message>

          <div class="columns">
            <div class="column">{{ 'campagne' | pluz(campaignsFiltered.length) }}</div>
            <div class="column is-8">
              <div class="columns">
                <div class="column is-4">
                  <b-field>
                    <b-select
                      expanded
                      placeholder="Canal"
                      icon-pack="far"
                      icon="mail-bulk"
                      size="is-small"
                      v-model="filterValue"
                      @input="filteredBy">
                      <option value="-1" selected>Email & SMS</option>
                      <option value="1">Email</option>
                      <option value="2">SMS</option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-8">
                  <b-field>
                    <search-bar
                      :campaigns="campaigns"
                      @update:data="campaignsFiltered = $event; filteredBy()">
                    </search-bar>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <b-table
            :data="!campaignsFiltered ? [] : campaignsFiltered.slice(0, 200).sort((a, b) => a.label.localeCompare(b.label))"
            :loading="isLoading"
            paginated
            per-page="7"
            pagination-rounded
            pagination-position="top"
            pagination-size="is-small"
            hoverable
            mobile-cards>
            <b-table-column v-slot="props" field="label" label="Nom de la campagne">
              {{ props.row.label }}
            </b-table-column>

            <b-table-column v-slot="props" field="start_date" label="Date début">
              {{ props.row.start_date }}
            </b-table-column>

            <b-table-column v-slot="props" field="end_date" label="Date fin">
              {{ props.row.end_date }}
            </b-table-column>

            <b-table-column v-slot="props" field="description" label="Description">
              {{ props.row.description }}
            </b-table-column>

            <b-table-column centered v-slot="props" field="channel" label="Canal">
              <b-tooltip
                :label="[props.row.channel === 1 ? 'Email' : props.row.channel === 2 ? 'SMS' : 'Courrier'].toString()"
                position="is-right"
                type="is-dark">
                <b-icon
                  pack="fas"
                  :icon="[props.row.channel === 1 ? 'at' : props.row.channel === 2 ? 'mobile-alt' : 'mailbox'].toString()"
                ></b-icon>
              </b-tooltip>
            </b-table-column>

            <b-table-column centered v-slot="props" field="campaign_type" label="Validation centrale">
              <b-icon
                pack="fas"
                :icon="props.row.campaign_type == 4 ? 'check-square' : 'times-square'"
                :type="props.row.campaign_type == 4 ? 'is-success' : 'is-danger'"
              ></b-icon>
            </b-table-column>

            <b-table-column label="Voir" centered v-slot="props">
              <span v-if="props.row.channel === 1" @click="openCampaignContent(props.row.deliveries[0].thumbnail)">
                <b-icon
                  size="is-small"
                  pack="fas"
                  icon="search"
                  style="cursor: pointer">
                </b-icon>
              </span>
            </b-table-column>

            <b-table-column v-slot="props" centered width="100">
<!--              <b-button-->
<!--                @click="runOrder(props.row)"-->
<!--                type="is-twitter"-->
<!--                size="is-small"-->
<!--                :loading="props.row.id === campaignLoading"-->
<!--                :disabled="campaignLoading">-->
<!--                Commander-->
<!--              </b-button>-->
              <b-tooltip
                :active="$store.state.blockSMS && props.row.channel === 2"
                multilined
                position="is-left"
                class="b-tooltip-custom"
                type="is-dark" size="is-large"
                label="Vous ne pouvez pas commander de SMS">
                <b-button
                  @click="runOrder(props.row)"
                  type="is-twitter"
                  size="is-small"
                  :loading="props.row.id === campaignLoading"
                  :disabled="campaignLoading || ($store.state.blockSMS && props.row.channel === 2)">
                  Commander
                </b-button>
              </b-tooltip>
            </b-table-column>

            <b-table-column
              v-if="this.$store.state.permission >= 40"
              width="30"
              v-slot="props">
              <b-field>
                <b-tooltip
                  label="Supprimer la campagne"
                  type="is-dark"
                  size="is-small"
                  position="is-left">
                  <b-button
                    size="is-small"
                    type="is-danger"
                    icon-pack="fas"
                    icon-right="trash"
                    @click="deleteCampaign(props.row)"
                    :loading="props.row.id === campaignLoading"
                    :disabled="campaignLoading" />
                </b-tooltip>
              </b-field>
            </b-table-column>

            <template #empty>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="dolly-flatbed-empty"
                      pack="fas"
                      size="is-large">
                    </b-icon>
                    <br>
                    Aucune campagne à afficher
                  </p>
                </div>
              </section>
            </template>
          </b-table>

          <b-modal :active.sync="modalCampaignThumbnail.active" class="model-custom" >
            <b-loading v-model="modalCampaignThumbnail.load" :is-full-page="false"></b-loading>
            <template
              v-for="image in modalCampaignThumbnail.image"
              v-if="image">
              <img
                style="min-height: 100px;"
                :src="`${image}?t=${new Date().getTime()}`"
                @load="modalCampaignThumbnail.load = false"
                @error="appendThumbnailImage()">
            </template>
          </b-modal>
        </b-message>
      </template>

      <template v-else>
        <Order></Order>
      </template>
    </div>
  </section>
</template>
<script>
import SearchBar from '@/components/Campaigns/SearchBar';
import Order from "@/views/Campaign/Order/Home";

export default {
  name: 'catalog',
  components: {
    SearchBar,
    Order,
  },
  data () {
    return {
      modalCampaignThumbnail: {
        active: false,
        image: [''],
        iter: 500,
        load: true,
      },
      ordersInProgress: [],
      campaignLoading: false,
      filterValue: undefined,
      campaigns: [],
      campaignsFiltered: [],
      isLoading: false,
      dates: {
        start_date: `${this.$moment().format("YYYY-MM-DD")} 23:59:00`,
        end_date: `${this.$moment().format("YYYY-MM-DD")} 00:00:00`
      },
      currentBrandId: this.$entity.getBrandId()
    }
  },
  methods: {
    appendThumbnailImage() {
      if(this.modalCampaignThumbnail.iter >= 150) {
        this.modalCampaignThumbnail.load = false;

        this.$buefy.toast.open({
          duration: 5000,
          message: 'Impossible de charger l\'image. Veuillez réessayer !',
          position: 'is-top',
          type: 'is-danger'
        });

        this.modalCampaignThumbnail.active = false;
        return;
      }

      let saveImages = this.modalCampaignThumbnail.image;

      this.modalCampaignThumbnail.image = [];
      this.modalCampaignThumbnail.image = saveImages;

      this.modalCampaignThumbnail.iter += 1;
    },
    openCampaignContent(thumbnail) {
      this.modalCampaignThumbnail = {
        active: true,
        image: [thumbnail],
        iter: 0,
        load: true,
      }
    },
    deleteCampaign(campaign) {
      this.$buefy.dialog.confirm({
        message: 'Êtes-vous sûr de vouloir supprimer la campagne ? <br>Après cette action, la campagne sera supprimée et ne pourra plus être réimportée !',
        type: 'is-warning',
        size: 'is-small',
        hasIcon: true,
        icon: 'exclamation-triangle',
        confirmText: 'Confirmer la suppression de la campagne',
        cancelText: 'Annuler',
        onConfirm: () => {
          this.isOrderLoading = campaign.id;
          this.$me.delete(`/campaigns-catalog/delete-campaign/${campaign.id}/`, {params:{storeFilter: false}})
            .then(() => {
              this.campaignsFiltered.splice(
                this.campaignsFiltered.findIndex(
                  element => element.id === campaign.id
                ), 1
              );

              this.$buefy.toast.open({
                queue: false,
                message: `La campagne n°${campaign.id} a été supprimée !`,
                position: 'is-top',
                type: 'is-success'
              });
            })
            .finally(() => {
              this.campaignLoading = false;
            });
        }
      });
    },
    createNewOrder(campaign) {
      this.createOrder(campaign);
    },
    runOrder: function (campaign) {
      this.campaignLoading = campaign.id;

      this.$me.get('campaigns-orders/?campaign_family=1')
        .then(({data}) => {
          this.ordersInProgress = data.filter(order => order.campaign.id === campaign.id);
        })
        .finally(() => {
          if(this.ordersInProgress.filter(order => [2, 3].includes(order.status)).length > 0 ) {
            this.$buefy.modal.open({
              parent: this,
              component: () => import('@/components/Campaigns/OrdersAlreadyProgress'),
              props: {
                orders: this.ordersInProgress.filter(order => [2, 3].includes(order.status)),
                campaign: campaign
              },
              hasModalCard: true,
              customClass: 'custom-class custom-class-2',
              trapFocus: true,
              onCancel: () => {
                this.campaignLoading = false;
              },
              events: {
                closeModal: () => {
                  this.campaignLoading = false;
                },
                createNewOrder: (campaign) => {
                  this.createNewOrder(campaign);
                }
              }
            });

          } else {
            this.createOrder(campaign);
          }
        })
    },
    createOrder: function (campaign) {
      this.$me.post('/campaigns-orders/create-campaign-order/', {
        label: campaign.label || '',
        description: campaign.description || '',
        store: this.$entity.getId(),
        channel: campaign.channel,
        campaign: campaign.id,
        schedule: '2000-01-01T00:00:01',
        content: '',
        status: 2,
      })
        .then(({id}) => {
          this.$router.push({
            name: 'Campaign',
            params: {
              orderId: id,
            }
          });
        })
        .finally(() => this.campaignLoading = false);
    },
    filteredBy: function() {
      let filteredValue = parseInt(this.filterValue);
      if(filteredValue >= 0) {
        this.campaignsFiltered = this.campaignsFiltered.filter(el => el.channel === filteredValue);
      }
    },
    async getCampaigns (url) {
      await this.$me.get(url)
        .then(({ next, previous, count, data}) => {
          data.forEach((campaign) => {
            if (campaign.deliveries.length == 1) {
              campaign.start_date = campaign.start_date ? this.$moment(campaign.start_date).format("DD-MM-YYYY") : "Pas de date"
              campaign.end_date = campaign.end_date ? this.$moment(campaign.end_date).format("DD-MM-YYYY") : "Pas de date"
              campaign.channel = campaign.deliveries[0].channel
              this.campaigns = [...this.campaigns, campaign]
            }
          });

          if(next) this.getCampaigns(next)
        })
        .finally(() => {
          this.campaignsFiltered = this.campaigns

          this.isLoading = false

          this.$me.get('campaigns-catalog-limit/')
            .then(({data}) => {
              this.$store.state.alertSMS = data
            })
            .catch(() => {
              this.$store.state.alertSMS = false
            })
        })
    },
  },
  async mounted () {
    this.isLoading = true;
    await this.getCampaigns(`/campaigns-catalog/?family=1&start_date_before=${this.dates.start_date}&end_date_after=${this.dates.end_date}&brand=${this.currentBrandId}&limit=200`);
  }
}
</script>
